function toggleMenu(header, html) {
    header.classList.toggle('open');
    html.classList.toggle('noscroll');
}

export default () => {
    const header = document.getElementById('masthead');
    const menuButton = document.getElementById('nav-toggle');
    const html = document.getElementById('html');
    if (menuButton) {
        menuButton.addEventListener('click', () => {
            toggleMenu(header, html);
        });
    }
};
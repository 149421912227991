export default () => {
    const div = document.getElementById('zsiqpersonalize');
    const buttons = document.querySelectorAll('.mlachat');

    if (!div) {
        return;
    }

    buttons.forEach(button => {
        button.addEventListener('click', (e) => {
            div.classList.toggle('show', !div.classList.contains('show'));
            e.preventDefault();
        });
    });
};

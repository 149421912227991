import { Validation } from 'bunnyjs/src/Validation';

const customFormSubmit = form => {
    Validation.validateSection(form).then(result => {
        if (result === true) {
            // TODO: Add recaptcha
            const recaptchaField = form.querySelector('.g-recaptcha');
            if (form.classList.contains('recaptcha') && recaptchaField) {
                grecaptcha.execute();
            } else {
                form.submit();
            }
        } else {
            // form error, result is array of invalid inputs
            Validation.focusInput(result[0]);
        }
    });
};

const initValidation = form => {
    Validation.init(form, true);
    form.querySelector('[type="submit"]').addEventListener('click', e => {
        e.preventDefault();
        customFormSubmit(form);
    });
};

export default () => {
    const forms = document.querySelectorAll('form');
    forms.forEach(form => {
        initValidation(form);
    });
};
